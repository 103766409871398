/** @format */
import * as React from 'react';
import assignmentData from './assignments.json';
import {
	TableContainer,
	Table,
	TableBody,
	Paper,
	TableCell,
	TableRow,
	Typography,
	Tabs,
	Tab,
	Box,
	Card,
	CardContent,
	Link,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`assignment-tabpanel-${index}`}
			aria-labelledby={`assignment-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `assignment-tab-${index}`,
	};
}

const AssignmentsList = (props) => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<div className='table-header'>
				<h4>Assignments</h4>
				<Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label='assignment tabs'
						textColor='tertiary'
						indicatorColor='tertiary'
					>
						<Tab
							label='All'
							{...a11yProps(0)}
						/>
						<Tab
							label='Due Next'
							{...a11yProps(1)}
						/>
					</Tabs>
				</Box>
			</div>
			<CustomTabPanel
				value={value}
				index={0}
			>
				<Card className='table-wrapper'>
					<CardContent sx={{ p: 0, pb: '0 !important' }}>
						<TableContainer component={Paper}>
							<Table>
								<TableBody>
									{assignmentData.map((assignment, index) => {
										return (
											<TableRow className='assignment-row'>
												<TableCell width={'10%'}>
													<button className='due-date'>
														{assignment.due_date ? (
															assignment.due_date
														) : (
															<CalendarTodayIcon fontSize='small' />
														)}
													</button>
												</TableCell>
												<TableCell
													scope='row'
													className='row-info'
												>
													<Typography
														variant='body3'
														mb={2}
													>
														<Link
															href='https://ux.pearson.com/prototypes/integrated-assignment-experience/#/a&p'
															target='_blank'
														>
															{assignment.name}
														</Link>
													</Typography>
													<Typography
														variant='subtitle'
														color='text.secondary'
														className='assignment-metadata'
													>
														<span>{assignment.type}</span> |
														<span>{assignment.time} mins</span> |
														<span>{assignment.points} pts</span>
													</Typography>
												</TableCell>

												<TableCell
													className={`assgn-status ${
														assignment.status ? assignment.status : ''
													}`}
													width={'8%'}
												>
													{assignment.status === 'complete' ? (
														<CheckCircleIcon
															color='primary'
															fontSize='small'
														/>
													) : assignment.status === 'incomplete' ? (
														<ErrorIcon
															color='primary'
															fontSize='small'
														/>
													) : assignment.status === 'locked' ? (
														<LockIcon fontSize='small' />
													) : (
														<></>
													)}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={1}
			>
				Due next assignments
			</CustomTabPanel>
		</>
	);
};

export default AssignmentsList;
