/** @format */
import { useEffect } from "react";
import { Button, CardContent, Typography, Card } from "@mui/material";
import { Box } from "@mui/system";
import BasicDateCalendar from "./Calendar";
import AssignmentsList from "./AssignmentsList";
import BookCover from "../assets/images/collegealgebra.png";
import Donut from "./Donut";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";

const Dashboard = (props) => {
  const theme = useTheme();
  const { sessionData } = useParams();
  // Remove "sessionData=" from the string
  const encodedData = sessionData?.substring("sessionData=".length);
  const decodedSessionData = encodedData ? JSON.parse(atob(encodedData)) : null;

  return (
    <div className="main-content">
      <h2 className="page-title">Course Home</h2>
      <div className="wrapper">
        <div className="dashboard-2col">
          <div className="col1">
            { decodedSessionData?.username ? (
              <h3>Welcome {decodedSessionData?.username}!</h3>
            ) : (
              <h3>Welcome back!</h3>
            )}
            <AssignmentsList />
          </div>
          <div className="col2">
            <Card sx={{ mb: 6, mt: 4 }}>
              <CardContent
                sx={{
                  pb: "1rem !important",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Donut score={90.75} />
                  <div>
                    <Typography variant={"subtitle4"}>Overall score</Typography>
                    <Typography variant={"body2"} color={"secondary"}>
                      How is this calculated
                    </Typography>
                  </div>
                </Box>
              </CardContent>
            </Card>

            <Card sx={{ mb: 6 }}>
              <CardContent>
                <BasicDateCalendar />
              </CardContent>
            </Card>

            <Card>
              <CardContent
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  pb: "1rem",
                }}
              >
                <img src={BookCover} alt="" />
                <div>
                  <Typography variant="subtitle4" component="h4" mb={2}>
                    Anatomy & Physiology
                  </Typography>
                  <Typography variant="label3" component="p" mb={2}>
                    7th edition
                    <br />
                    Elaine N Marieb, Katja Hoehn
                  </Typography>
                  <Button size="small" mt={2}>
                    Open eTextbook
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
