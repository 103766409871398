/** @format */

import pageIntegratedTOC from '../assets/images/page_integrated_toc.png';

export default function IntegratedReviewTOC(props) {
	return (
		<div className={`old-page ${props.mode}`}>
			<h2 className='page-title'>Integrated Review Table of Contents</h2>
			<img
				src={pageIntegratedTOC}
				alt=''
				style={{ display: 'block', margin: '0 auto' }}
			/>
		</div>
	);
}
