/** @format */

import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Link } from "@mui/material";
import Header from "./Header";
import MainNav from "./MainNav";
import Dashboard from "./dashboard/Dashboard";
import Results from "./results/Results";
import pearsonLogo from "./assets/images/logo_pearson.png";
import EText from "./etext/EText";
import IntegratedReviewTOC from "./integratedTOC/IntegratedReviewTOC";
import PurchaseOptions from "./purchaseoptions/PurchaseOptions";
import MediaLibrary from "./medialibrary/MediaLibrary";
import DocumentSharing from "./documentsharing/DocumentSharing";

const App = (props) => {
  var location = window.location;

  //Load this string from localStorage
  const lastActiveNav = localStorage.getItem("lastActiveNav");

  const [activeNav, setActiveNav] = useState(
    lastActiveNav || props.defaultActive
  );
  const [appMode, setAppMode] = useState("standalone");

  //This sets the item to localStorage and changes the state index
  function changeActiveNav(newNav) {
    localStorage.setItem("lastActiveNav", newNav);
    setActiveNav(newNav);
  }

  //This re-renders when the route changes
  useEffect(() => {
    var activeNavItem = location.pathname;
    if (activeNavItem.indexOf("/") > -1) {
      activeNavItem =
        location.pathname.split("/")[location.pathname.split("/").length - 1];
    }
    changeActiveNav(activeNavItem);
    if (location.pathname.indexOf("/page") > -1) {
      setAppMode("ov2");
      document.getElementsByTagName("body")[0].classList.add("in-ov2");
    }
  }, [location]);

  return (
    <div className="body-wrap">
      {appMode === "standalone" && (
        <aside className="sidebar">
          <Link href="prototypes/modern-xl-instructor/" className="header-logo">
            <img src={pearsonLogo} alt="" />
          </Link>
          <MainNav activeNav={activeNav} />
        </aside>
      )}
      <main>
        {appMode === "standalone" && <Header />}
        <BrowserRouter basename="/prototypes/modern-xl-student">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/results" element={<Results />} />
            <Route
              path="/page/results"
              element={<Results mode={"full-page"} />}
            />
            <Route path="/etext" element={<EText />} />
            <Route
              path="/page/eTextbook"
              element={<EText mode={"full-page"} />}
            />
            <Route
              path="/page/media"
              element={<MediaLibrary mode={"full-page"} />}
            />
            <Route path="/page/reviewtoc" element={<IntegratedReviewTOC />} />
            <Route
              path="/page/purchaseoptions"
              element={<PurchaseOptions mode={"full-page"} />}
            />
            <Route
              path="/page/sharing"
              element={<DocumentSharing mode={"full-page"} />}
            />
            <Route exact path="/:sessionData" element={<Dashboard />} />
            <Route path="/page" element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
      </main>
    </div>
  );
};

export default App;
