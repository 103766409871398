/** @format */

'use strict';
var __assign =
	(this && this.__assign) ||
	function () {
		__assign =
			Object.assign ||
			function (t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s)
						if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
Object.defineProperty(exports, '__esModule', { value: true });
exports.modernxlLightTheme = void 0;
/** @format */
var material_1 = require('@mui/material/');
var system_1 = require('@mui/system');
var mxlDesignVariables = require('./tokens/tokens.modernxl');
exports.modernxlLightTheme = (0, material_1.createTheme)({
	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl'],
		values: {
			xs: 540,
			sm: 872,
			md: 1024,
			lg: 1440,
			xl: 1600,
		},
	},
	palette: {
		mode: 'light',
		common: {
			black: mxlDesignVariables.TOKEN_COLOR_THEME_BLACK,
			white: mxlDesignVariables.TOKEN_COLOR_THEME_WHITE,
		},
		primary: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY,
			disabled: (0, system_1.lighten)(
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY,
				0.38
			),
			contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_PRIMARY,
			container:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY_CONTAINER,
			containerText:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_PRIMARY_CONTAINER,
			hover:
				mxlDesignVariables.TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_PRIMARY_OPACITY_0_08,
		},
		secondary: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY,
			disabled: (0, system_1.lighten)(
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY,
				0.38
			),
			contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SECONDARY,
			container:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY_CONTAINER,
			containerText:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SECONDARY_CONTAINER,
			hover:
				mxlDesignVariables.TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SECONDARY_OPACITY_0_08,
		},
		tertiary: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY,
			disabled: (0, system_1.lighten)(
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY,
				0.38
			),
			contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_TERTIARY,
			container:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY_CONTAINER,
			containerText:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_TERTIARY_CONTAINER,
			hover:
				mxlDesignVariables.TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_TERTIARY_OPACITY_0_08,
		},
		error: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ERROR,
			disabled: (0, system_1.lighten)(
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ERROR,
				0.38
			),
			contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_ERROR,
			container: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ERROR_CONTAINER,
			containerText:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_ERROR_CONTAINER,
		},
		warning: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_WARNING,
			disabled: (0, system_1.lighten)(
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_WARNING,
				0.38
			),
			contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_WARNING,
			container:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_WARNING_CONTAINER,
			containerText:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_WARNING_CONTAINER,
		},
		info: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INFO,
			disabled: (0, system_1.lighten)(
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INFO,
				0.38
			),
			contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_INFO,
			container: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INFO_CONTAINER,
			containerText:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_INFO_CONTAINER,
		},
		success: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS,
			disabled: (0, system_1.lighten)(
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS,
				0.38
			),
			contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SUCCESS,
			container:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS_CONTAINER,
			containerText:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SUCCESS_CONTAINER,
		},
		base1: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SURFACE,
			contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SURFACE,
		},
		base2: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SURFACE_VARIANT,
			contrastText:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SURFACE_VARIANT,
		},
		grey: {
			50: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL0,
			100: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL10,
			200: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL20,
			300: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL30,
			400: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL40,
			500: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL50,
			600: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL60,
			700: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL70,
			800: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL80,
			900: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL90,
		},
		transparent: {
			transparent0: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_0,
			transparent8: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_8,
			transparent12: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_12,
			transparent16: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_16,
			transparent24: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_24,
			transparent32: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_32,
			transparent40: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_40,
			transparent48: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_48,
			transparent56: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_56,
			transparent64: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_64,
			transparent72: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_72,
			transparent80: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_80,
			transparent88: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_88,
			transparent96: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_96,
			transparentInverse0:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_0,
			transparentInverse8:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_8,
			transparentInverse12:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_12,
			transparentInverse16:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_16,
			transparentInverse24:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_24,
			transparentInverse32:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_32,
			transparentInverse40:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_40,
			transparentInverse48:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_48,
			transparentInverse56:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_56,
			transparentInverse64:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_64,
			transparentInverse72:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_72,
			transparentInverse80:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_80,
			transparentInverse88:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_88,
			transparentInverse96:
				mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_96,
		},
		text: {
			primary: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			primaryDisabled: (0, system_1.lighten)(
				mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
				0.38
			),
			secondary: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
			secondaryDisabled: (0, system_1.lighten)(
				mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
				0.38
			),
		},
		action: {
			active:
				mxlDesignVariables.TOKEN_COLOR_THEME_SURFACES_LIGHT_SURFACE_ACTIVE,
			hover: mxlDesignVariables.TOKEN_COLOR_THEME_SURFACES_LIGHT_SURFACE_HOVER,
			focus: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_FOCUS_A11Y,
		},
		gradient: {
			main: ''
				.concat(
					mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_LIGHT_GRADIENT_MAIN
						.gradientType,
					'-gradient(90deg, '
				)
				.concat(
					mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_LIGHT_GRADIENT_MAIN
						.stops[0].color,
					' '
				)
				.concat('30%, ')
				.concat(
					mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_LIGHT_GRADIENT_MAIN
						.stops[1].color,
					' '
				)
				.concat('90%)'),
			primary: ''
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT.gradientType,
					'-gradient(90deg, '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT.stops[0]
						.color,
					' '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT.stops[0]
						.position * 100,
					'%, '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT.stops[1]
						.color,
					' '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT.stops[1]
						.position * 100,
					'%)'
				),
			secondary: ''
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1
						.gradientType,
					'-gradient(90deg, '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1.stops[0]
						.color,
					' '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1.stops[0]
						.position * 100,
					'%, '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1.stops[1]
						.color,
					' '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1.stops[1]
						.position * 100,
					'%), '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0
						.gradientType,
					'-gradient(117.32deg, '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0.stops[0]
						.color,
					' '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0.stops[0]
						.position * 100,
					'%, '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0.stops[1]
						.color,
					' '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0.stops[1]
						.position * 100,
					'%)'
				),
			tertiary: ''
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT.gradientType,
					'-gradient(137.13deg, '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT.stops[0]
						.color,
					' '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT.stops[0]
						.position * 100,
					'%, '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT.stops[1]
						.color,
					' '
				)
				.concat(
					mxlDesignVariables
						.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT.stops[1]
						.position * 100,
					'%)'
				),
		},
		blur: {
			primary: 'blur(20px)',
		},
		inverse: {
			main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_SURFACE,
			contrastText:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_ON_SURFACE,
			primary: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_PRIMARY,
			secondary:
				mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_SECONDARY,
			tertiary: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_TERTIARY,
		},
		backgroundNoGradient:
			mxlDesignVariables.TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY98,
		divider: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_OUTLINE_VARIANT,
		dividerLight: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_OUTLINE,
	},
	spacing: 4,
	typography: {
		htmlFontSize: 16,
		fontFamily: '"Inter", Helvetica, sans-serif',
		fontSize:
			mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontSize,
		letterSpacing:
			mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.letterSpacing,
		lineHeight:
			mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.lineHeight /
			mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontSize,
		fontWeightLight: 300,
		fontWeightRegular:
			mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontWeight,
		fontWeightMedium: 600,
		fontWeightBold: 700,
		h1: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H1.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H1.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H1.letterSpacing,
		},
		h2: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H2.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H2.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H2.letterSpacing,
		},
		h3: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H3.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H3.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H3.letterSpacing,
		},
		subtitle1: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.fontSize,
		},
		subtitle2: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.fontSize,
		},
		subtitle3: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.fontSize,
		},
		subtitle4: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.fontSize,
		},
		body1: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontSize,
			textTransform:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.textCase,
		},
		body2: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.fontSize,
			textTransform:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.textCase,
		},
		body3: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
					.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
					.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
					.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
					.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
					.fontSize,
			textTransform:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
					.textCase,
		},
		label1: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL
					.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL.fontSize,
			textTransform:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL.textCase,
		},
		label2: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM
					.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM.fontSize,
			textTransform:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM.textCase,
		},
		label3: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE
					.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE.fontSize,
			textTransform:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE.textCase,
		},
		caption: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
					.fontSize,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
					.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
					.letterSpacing,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
					.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
					.fontSize,
			textTransform:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
					.textCase,
		},
		overline: {
			color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
			fontSize:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
					.fontSize,
			textDecoration:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
					.textDecoration,
			fontWeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
					.fontWeight,
			letterSpacing:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
					.letterSpacing,
			fontStyle:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
					.fontStyle,
			lineHeight:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
					.lineHeight /
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
					.fontSize,
			textTransform:
				mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
					.textCase,
		},
	},
	components: {
		MuiAccordion: {
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						background: theme.palette.gradient.main,
						backdropFilter: theme.palette.blur.primary,
						borderRadius: '8px',
						'&.MuiPaper-root': {
							borderRadius: '8px',
						},
						'&:before': {
							display: 'none',
						},
					};
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						borderTopLeftRadius: '8px',
						borderTopRightRadius: '8px',
						'&.Mui-expanded': {
							background: theme.palette.gradient.secondary,
							'& .MuiTypography-root': {
								color: ''.concat(theme.palette.common.white, ' !important'),
							},
						},
						'& .MuiAccordionSummary-expandIconWrapper': {
							'&.Mui-expanded': {
								'& .MuiSvgIcon-root': {
									color: ''.concat(
										theme.palette.transparent.transparentInverse96,
										' !important'
									),
								},
							},
						},
					};
				},
			},
		},
		MuiAlert: {
			defaultProps: {
				variant: 'standard',
			},
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						borderRadius: 8,
						'& .MuiAlert-action': {
							padding: '2px 0px 0px 16px',
						},
						'& .MuiAlert-message': {
							color: theme.palette.text.primary,
						},
						'& .MuiIconButton-root': {
							color: theme.palette.text.primary,
						},
					};
				},
				filledError: function (_a) {
					var ownerState = _a.ownerState,
						theme = _a.theme;
					return {
						backgroundColor: theme.palette.error.container,
					};
				},
				filledInfo: function (_a) {
					var ownerState = _a.ownerState,
						theme = _a.theme;
					return {
						backgroundColor: theme.palette.info.container,
					};
				},
				filledSuccess: function (_a) {
					var ownerState = _a.ownerState,
						theme = _a.theme;
					return {
						backgroundColor: theme.palette.success.container,
					};
				},
				filledWarning: function (_a) {
					var ownerState = _a.ownerState,
						theme = _a.theme;
					return {
						backgroundColor: theme.palette.warning.container,
					};
				},
				standardError: function (_a) {
					var ownerState = _a.ownerState,
						theme = _a.theme;
					return {
						backgroundColor: theme.palette.action.active,
					};
				},
				standardInfo: function (_a) {
					var ownerState = _a.ownerState,
						theme = _a.theme;
					return {
						backgroundColor: theme.palette.action.active,
					};
				},
				standardSuccess: function (_a) {
					var ownerState = _a.ownerState,
						theme = _a.theme;
					return {
						backgroundColor: theme.palette.action.active,
					};
				},
				standardWarning: function (_a) {
					var ownerState = _a.ownerState,
						theme = _a.theme;
					return {
						backgroundColor: theme.palette.action.active,
					};
				},
			},
		},
		MuiAutocomplete: {
			defaultProps: {},
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						padding: '1px 9px !important',
					},
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					color: 'inherit',
					fontSize:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
							.fontSize,
					textDecoration:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
							.textDecoration,
					fontWeight:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
							.fontWeight,
					fontStyle:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
							.fontStyle,
					fontStretch:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
							.fontStretch,
					letterSpacing:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
							.letterSpacing,
					lineHeight:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
							.lineHeight /
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
							.fontSize,
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
				},
			},
		},
		MuiBreadcrumbs: {
			defaultProps: {
				itemsBeforeCollapse: 0,
				maxItems: 4,
			},
			styleOverrides: {
				root: {
					'& .MuiLink-root': {
						fontSize: 14,
						display: 'flex',
						alignItems: 'center',
						gap: 8,
						svg: {
							fontSize: 16,
						},
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						color: theme.palette.text.primary,
					};
				},
			},
		},
		MuiButton: {
			defaultProps: {
				variant: 'outlined',
				disableElevation: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: function (_a) {
					var ownerState = _a.ownerState,
						theme = _a.theme;
					var modifyColor =
						theme.palette.mode === 'light' ? system_1.darken : system_1.lighten;
					var colorMap = {
						inherit: theme.palette.primary.main,
						primary: theme.palette.primary.main,
						secondary: theme.palette.secondary.main,
						tertiary: theme.palette.tertiary.main,
						warning: theme.palette.warning.main,
						info: theme.palette.info.main,
						success: theme.palette.success.main,
						error: theme.palette.error.main,
					};
					return __assign(
						__assign(
							__assign(
								__assign(
									__assign(
										__assign(
											{ borderRadius: '2rem' },
											ownerState.variant === 'outlined' && {
												border: '2px solid '.concat(theme.palette.text.primary),
												color: theme.palette.text.primary,
											}
										),
										{
											'&:hover': __assign(
												__assign(
													{ boxShadow: 'none' },
													ownerState.variant === 'contained' && {
														backgroundColor: modifyColor(
															colorMap[ownerState.color || 'primary'],
															0.08
														),
													}
												),
												ownerState.variant === 'outlined' && {
													border: '2px solid '.concat(
														theme.palette.text.primary
													),
													backgroundColor:
														theme.palette.transparent.transparent8,
												}
											),
											'&:focus-visible': __assign(
												__assign(
													{
														outlineOffset: '0',
														outline: '2px solid '.concat(
															theme.palette.action.focus
														),
														boxShadow: 'none',
													},
													ownerState.variant === 'contained' && {
														backgroundColor: modifyColor(
															colorMap[ownerState.color || 'primary'],
															0.12
														),
													}
												),
												ownerState.variant === 'outlined' && {
													backgroundColor:
														theme.palette.transparent.transparent12,
												}
											),
											'&:active': __assign(
												__assign(
													{},
													ownerState.variant === 'contained' && {
														backgroundColor: modifyColor(
															colorMap[ownerState.color || 'primary'],
															0.16
														),
													}
												),
												ownerState.variant === 'outlined' && {
													backgroundColor:
														theme.palette.transparent.transparent16,
													outline: 'none',
												}
											),
											'&.MuiButton-text': {
												'&:hover': {
													backgroundColor:
														theme.palette.transparent.transparent8,
													outline: 'none',
												},
												'&:focus-visible': {
													backgroundColor:
														theme.palette.transparent.transparent12,
												},
												'&:active': {
													backgroundColor:
														theme.palette.transparent.transparent16,
													outline: 'none',
												},
											},
											'&.Mui-disabled': {
												backgroundColor:
													theme.palette.transparent.transparent16,
												color: theme.palette.transparent.transparent40,
											},
										}
									),
									ownerState.size === 'large' && {
										padding: '12px 34px',
										fontSize:
											mxlDesignVariables
												.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE.fontSize,
										fontWeight:
											mxlDesignVariables
												.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE.fontWeight,
										letterSpacing:
											mxlDesignVariables
												.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
												.letterSpacing,
										textTransform:
											mxlDesignVariables
												.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE.textCase,
										textDecoration:
											mxlDesignVariables
												.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
												.textDecoration,
										fontStyle:
											mxlDesignVariables
												.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE.fontStyle,
										fontStretch:
											mxlDesignVariables
												.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE.fontStretch,
										lineHeight:
											mxlDesignVariables
												.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE.lineHeight /
											mxlDesignVariables
												.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE.fontSize,
									}
								),
								ownerState.size === 'medium' && {
									padding: '10px 24px',
									fontSize:
										mxlDesignVariables
											.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM.fontSize,
									textDecoration:
										mxlDesignVariables
											.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM
											.textDecoration,
									fontWeight:
										mxlDesignVariables
											.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM.fontWeight,
									fontStyle:
										mxlDesignVariables
											.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM.fontStyle,
									fontStretch:
										mxlDesignVariables
											.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM.fontStretch,
									letterSpacing:
										mxlDesignVariables
											.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM.letterSpacing,
									textTransform:
										mxlDesignVariables
											.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM.textCase,
									lineHeight:
										mxlDesignVariables
											.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM.lineHeight /
										mxlDesignVariables
											.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM.fontSize,
								}
							),
							ownerState.size === 'small' && {
								padding: '6px 16px',
								fontSize:
									mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
										.fontSize,
								textDecoration:
									mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
										.textDecoration,
								fontWeight:
									mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
										.fontWeight,
								fontStyle:
									mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
										.fontStyle,
								fontStretch:
									mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
										.fontStretch,
								letterSpacing:
									mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
										.letterSpacing,
								textTransform:
									mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
										.textCase,
								lineHeight:
									mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
										.lineHeight /
									mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
										.fontSize,
							}
						),
						ownerState.variant === 'text' && {
							'&:hover': {
								boxShadow: 'none',
							},
						}
					);
				},
			},
		},
		MuiButtonGroup: {
			defaultProps: {
				disableElevation: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					'& .MuiButtonBase-root': {
						borderRadius: 4,
					},
				},
			},
		},
		MuiCard: {
			defaultProps: {
				elevation: 1,
			},
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						background: theme.palette.gradient.main,
						'&:hover': {
							background: theme.palette.action.hover,
						},
						'&.active': {
							background: theme.palette.action.active,
						},
					};
				},
			},
		},
		MuiCardActionArea: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					background: 'linear-gradient(0, '
						.concat(
							mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_1,
							', '
						)
						.concat(
							mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_1,
							'), '
						)
						.concat(
							mxlDesignVariables
								.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0.gradientType,
							'-gradient(135deg, '
						)
						.concat(
							mxlDesignVariables
								.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0.stops[0]
								.color,
							' '
						)
						.concat(
							mxlDesignVariables
								.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0.stops[0]
								.position * 100,
							'%, '
						)
						.concat(
							mxlDesignVariables
								.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0.stops[1]
								.color,
							' '
						)
						.concat(
							mxlDesignVariables
								.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0.stops[1]
								.position * 100,
							'%)'
						),
					backgroundAttachment: 'fixed',
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme,
						checked = _a.checked;
					return {
						'&:hover': {
							backgroundColor: theme.palette.transparent.transparent8,
						},
						'& .MuiSvgIcon-root': {
							fill: checked
								? theme.palette.secondary.main
								: theme.palette.text.secondary,
							color: theme.palette.secondary.contrastText,
							fontSize: 24,
						},
					};
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					fontSize:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontSize,
					letterSpacing:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.letterSpacing,
					fontWeight:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontWeight,
					fontStyle:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontStyle,
					lineHeight:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.lineHeight /
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontSize,
					textDecoration:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.textDecoration,
					textTransform:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.textCase,
				},
			},
		},
		MuiDialog: {
			defaultProps: {
				PaperProps: {
					elevation: 3,
				},
			},
			styleOverrides: {
				paper: {
					borderRadius: 16,
					padding: 24,
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					fontSize: 24,
					fontWeight: 600,
					lineHeight: 1.5,
					alignItems: 'center',
					display: 'flex',
					gap: 8,
				},
			},
		},
		MuiFab: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					display: 'flex',
					gap: 4,
				},
			},
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'outlined',
			},
			styleOverrides: {
				root: {
					'& [class*=MuiFormHelperText-root]': {
						marginLeft: '0 !important',
					},
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					borderRadius: 24,
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					marginLeft: 0,
					fontSize:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontSize,
					textDecoration:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.textDecoration,
					fontWeight:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontWeight,
					fontStyle:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontStyle,
					fontStretch:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontStretch,
					letterSpacing:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.letterSpacing,
					lineHeight:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.lineHeight /
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontSize,
					textTransform:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.textCase,
				},
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						'&:focus-visible': {
							backgroundColor: theme.palette.transparent.transparent16,
						},
						'&:hover': {
							backgroundColor: theme.palette.transparent.transparent8,
						},
						'&.Mui-disabled': {
							color: theme.palette.transparent.transparent40,
						},
					};
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontSize:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT.fontSize,
					textDecoration:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
							.textDecoration,
					fontWeight:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
							.fontWeight,
					fontStyle:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
							.fontStyle,
					fontStretch:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
							.fontStretch,
					letterSpacing:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
							.letterSpacing,
					lineHeight:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
							.lineHeight /
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT.fontSize,
					height: '2.5rem',
					'& legend': {
						display: 'none',
					},
					'& [class*=MuiOutlinedInput-notchedOutline]': {
						top: 0,
					},
				},
				sizeSmall: {
					height: '2rem',
				},
				sizeLarge: {
					height: '3rem',
				},
			},
		},
		MuiInputLabel: {
			defaultProps: {
				disableAnimation: true,
				shrink: true,
			},
			styleOverrides: {
				root: {
					position: 'static',
					transform: 'none',
					padding: '6px 0',
					fontSize:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontSize,
					letterSpacing:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.letterSpacing,
					fontWeight:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontWeight,
					fontStyle:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontStyle,
					lineHeight:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.lineHeight /
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.fontSize,
					textDecoration:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.textDecoration,
					textTransform:
						mxlDesignVariables
							.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
							.textCase,
				},
			},
		},
		MuiLink: {
			defaultProps: {
				underline: 'hover',
				color: 'inherit',
			},
		},
		MuiListItemButton: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiMenu: {
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						'& .MuiPaper-root': {
							borderRadius: '8px',
						},
					};
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						padding: '6px 12px',
						backgroundColor: theme.palette.base1.main,
						'&.MuiMenuItem-root.Mui-selected': {
							backgroundColor: theme.palette.base1.main,
						},
						'&:hover, &.MuiMenuItem-root.Mui-selected:hover': {
							backgroundColor: theme.palette.secondary.container,
							'& .MuiSvgIcon-root': {
								fill: theme.palette.secondary.contrastText,
							},
							'& .MuiTypography-root': {
								color: theme.palette.secondary.containerText,
							},
						},
					};
				},
			},
		},
		MuiPagination: {
			defaultProps: {
				color: 'standard',
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						background: theme.palette.base1.main,
					};
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						borderRadius: '20px',
					},
				},
			},
		},
		MuiRadio: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: function (_a) {
					var palette = _a.theme.palette;
					return {
						'&:hover': {
							backgroundColor: palette.transparent.transparent8,
						},
					};
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					fontSize:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT.fontSize,
					fontWeight:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
							.fontWeight,
					fontStyle:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
							.fontStyle,
					letterSpacing:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
							.letterSpacing,
				},
				sizeSmall: {
					height: '2rem',
				},
				sizeLarge: {
					height: '3rem',
				},
			},
		},
		MuiSwitch: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					padding: '7px 5px',
				},
				switchBase: function (_a) {
					var theme = _a.theme;
					return {
						padding: '10px 12px 10px 8px',
						'&.Mui-checked ': {
							padding: '10px 8px 10px 12px',
						},
						'& > span.MuiSwitch-thumb': {
							backgroundColor: theme.palette.base2.main,
						},
						'&.Mui-checked > span.MuiSwitch-thumb': {
							backgroundColor: theme.palette.secondary.contrastText,
						},
						'& + span.MuiSwitch-track': {
							border: '1px solid '.concat(
								theme.palette.transparent.transparent0,
								' !important'
							),
							backgroundColor: ''.concat(
								theme.palette.transparent.transparent48,
								' !important'
							),
						},
						'&.Mui-checked + span.MuiSwitch-track': {
							border: '1px solid '.concat(
								theme.palette.secondary.main,
								' !important'
							),
							backgroundColor: ''.concat(
								theme.palette.secondary.main,
								' !important'
							),
						},
					};
				},
				thumb: function (_a) {
					var theme = _a.theme;
					return {
						width: 18,
						height: 18,
						boxShadow: 'none',
					};
				},
				track: function (_a) {
					var theme = _a.theme;
					return {
						borderRadius: 14,
						opacity: '1 !important',
						backgroundColor: ''.concat(
							theme.palette.secondary.main,
							' !important'
						),
					};
				},
			},
		},
		MuiTab: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						textTransform: 'none',
						borderRadius: 4,
						'&:hover': {
							backgroundColor: theme.palette.action.hover,
						},
						'&.Mui-selected': {
							color: theme.palette.primary.main,
							'&:hover': {
								backgroundColor: theme.palette.action.hover,
							},
						},
					};
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: function (_a) {
					var theme = _a.theme;
					return {
						height: 4,
						backgroundColor: theme.palette.primary.main,
					};
				},
			},
		},
		MuiTableContainer: {
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						/*background: theme.palette.gradient.main,
						backdropFilter: theme.palette.blur.primary,*/
						background: 'transparent',
						borderRadius: '8px',
						'&.MuiPaper-root': {
							borderRadius: '8px',
						},
						'&:before': {
							display: 'none',
						},
					};
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					fontSize:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
							.fontSize,
					textDecoration:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
							.textDecoration,
					fontWeight:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
							.fontWeight,
					fontStyle:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
							.fontStyle,
					fontStretch:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
							.fontStretch,
					letterSpacing:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
							.letterSpacing,
					lineHeight:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
							.lineHeight /
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
							.fontSize,
					textTransform:
						mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
							.textCase,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						paddingLeft: '16px',
					};
				},
			},
		},

		MuiTextField: {
			defaultProps: {},
			styleOverrides: {
				root: function (_a) {
					var theme = _a.theme;
					return {
						borderRadius: '4px',
						color: theme.palette.text.secondary,
						'& .MuiOutlinedInput-root': {
							backgroundColor: theme.palette.action.active,
							color: theme.palette.text.secondary,
							'&.Mui-focused fieldset': {
								borderColor: theme.palette.secondary.main,
							},
						},
					};
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				arrow: true,
			},
			styleOverrides: {
				tooltip: function (_a) {
					var theme = _a.theme;
					return {
						borderRadius: 4,
						backgroundColor: theme.palette.grey[600],
						fontSize:
							mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.fontSize,
						textDecoration:
							mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP
								.textDecoration,
						fontWeight:
							mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP
								.fontWeight,
						fontStyle:
							mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.fontStyle,
						fontStretch:
							mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP
								.fontStretch,
						letterSpacing:
							mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP
								.letterSpacing,
						lineHeight:
							mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP
								.lineHeight /
							mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.fontSize,
						textTransform:
							mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.textCase,
					};
				},
				tooltipArrow: function (_a) {
					var theme = _a.theme;
					return {
						backgroundColor: theme.palette.grey[600],
					};
				},
			},
		},
	},
});
