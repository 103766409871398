/** @format */
import { Link, MenuList, MenuItem } from '@mui/material';

const MainNav = (props) => {
	return (
		<MenuList className='main-menu'>
			<MenuItem selected={props.activeNav === 'home' || props.activeNav === ''}>
				<Link href='/prototypes/modern-xl-student/'>Home</Link>
			</MenuItem>
			<MenuItem selected={props.activeNav === 'results'}>
				<Link href='/prototypes/modern-xl-student/results'>Results</Link>
			</MenuItem>
			<MenuItem>
				<Link href='/prototypes/modern-xl-student/etext'>eTextbook</Link>
			</MenuItem>
			<MenuItem>
				<Link href='/prototypes/modern-xl-student/blank'>Menu 4</Link>
			</MenuItem>
			<MenuItem>
				<Link href='/prototypes/modern-xl-student/blank'>Menu 5</Link>
			</MenuItem>
		</MenuList>
	);
};

export default MainNav;
