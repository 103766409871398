/** @format */

import { useEffect, useRef } from 'react';

const Donut = (props) => {
	const score = props.score;
	const donutRef = useRef(null);

	useEffect(() => {
		const dashArrayTotal = 100.5; //2 * pie * radius
		let progressPercent = parseInt(
			props.score
		); /*(score.totalCorrect / score.totalQuestions) * 100; */
		let dashOffsetVal =
			0 - (dashArrayTotal - (dashArrayTotal * progressPercent) / 100);

		donutRef.current.setAttribute('stroke-dasharray', dashArrayTotal);
		donutRef.current.setAttribute('stroke-dashoffset', dashOffsetVal);
	}, [score]);
	return (
		<div className='donut-chart'>
			<div className='score'>{score}%</div>
			<svg
				viewBox='0 0 40 40'
				className='donut'
			>
				<circle
					className='donut-hole'
					cx='20'
					cy='20'
					r='16'
					fill='none'
				></circle>
				<circle
					className='donut-ring'
					cx='20'
					cy='20'
					r='16'
					fill='transparent'
					strokeWidth='2'
				></circle>
				<circle
					ref={donutRef}
					className='donut-segment donut-segment-2'
					cx='20'
					cy='20'
					r='16'
					fill='transparent'
					strokeWidth='3'
					strokeDasharray='0' //Set in Useeffect
					strokeDashoffset='25'
				></circle>
			</svg>
		</div>
	);
};

export default Donut;
