/** @format */

import PageScores from '../assets/images/page_scores.png';

export default function Results(props) {
	return (
		<div className={`old-page ${props.mode}`}>
			<h2 className='page-title'>Scores</h2>
			<img
				src={PageScores}
				alt=''
			/>
		</div>
	);
}
